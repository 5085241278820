@import '../variables';

h1, .h1 {
	font-size: @heading-1-size;
	font-weight: bold;
}

h2, .h2 {
	font-size: @heading-2-size;
	font-weight: bold;
}

h3, .h3 {
	font-size: @heading-3-size;
	font-weight: bold;
}

h4, .h4 {
	font-size: @heading-4-size;
}

h5, .h5 {
	font-size: @heading-5-size;
}
