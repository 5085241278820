.ant-radio {
	.ant-radio-inner {
		box-shadow: inset 0 0 10px rgba(0, 0, 0, .25);
	}

	&, &:hover, &.ant-radio-checked {
		.ant-radio-inner {
			border-color: @primary-color;
		}
	}

	&.ant-radio-checked {
		&:after {
			width: @radio-size;
			height: @radio-size;
			border-color: @primary-color;
		}
	}
}
