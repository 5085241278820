.right {
	float: right;
}

.justify-content-start {
	justify-content: flex-start;
}

.justify-content-end {
	justify-content: flex-end;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-between {
	justify-content: space-between;
}

.align-items-start {
	align-items: flex-start;
}

.align-items-center {
	align-items: center;
}

.align-items-end {
	align-items: flex-end;
}

.flex-column {
	flex-direction: column;
}

.d-flex {
	display: flex !important;
}
.bold {
	font-weight: bold;
}

.d-block {
	display: block !important;
}

.flex-wrap {
	flex-wrap: wrap;
}

.relative {
	position: relative;
}

