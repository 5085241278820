.ant-notification-notice {
  .ant-notification-notice-message {
    .standard-text();
    .title();
  }
  .ant-notification-notice-description {
    .standard-text();
    color: @red-2 !important;
  }
  &.error {
    color: @red-2 !important;
    .ant-notification-notice-message {
      color: @red-2 !important;
    }
  }
  &.warn {
    color: @yellow-1 !important;
    .ant-notification-notice-message {
      color: @yellow-1 !important;
    }
  }
}
