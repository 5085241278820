.ol-attribution {
	display: none !important;
}
.ol-rotate-reset {
	display: none;
}
.ol-zoom {
	max-width: 50px;
	width: auto;
	button {
		width: auto;
		height: auto;
		padding: 0;
	}
}
