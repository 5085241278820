.ant-modal-title {
	* {
		.standard-text();
	}
}
.ant-modal  {
	top: 10px !important;
}
.ant-modal-header {
	padding: 16px 54px 16px 24px !important;
}
.ant-modal-confirm {
	.ant-modal-body {
		padding: 27px !important;
		.ant-modal-confirm-title {
			font-size: 20px;
			line-height: 31px;
			margin-bottom: 27px;
		}
		.anticon {
			display: none !important;
		}
		.ant-modal-confirm-content {
			margin-left: 0 !important;
		}
		.ant-modal-confirm-btns {
			display: flex;
			width: 100%;
			float: none;
			justify-content: flex-end;
		}
	}

}
.ant-modal-footer {
	display: flex;
	justify-content: flex-end;
}
.ant-btn  {
	.standard-text();
	display: flex;
	align-items: center;
	white-space: nowrap;
	border-radius: 0px;
	padding: 0px 30px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0,0.2);
	-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0,0.2);
	cursor: pointer;
	outline: none ;
	height: 42px !important;
	&:first-child {
			background-color: @green-base;
			border: 2px solid @green-base;
			color: @white !important;
			&:hover {
				background-color: @green-10;
				border-color: @green-10;
			}
			&:active, &:focus {
				background-color: @green-10;
				border-color: @green-10;
		}
	}
}
