@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700;900&display=swap');

@import '~ng-zorro-antd/ng-zorro-antd';
@import 'variables';

// Global elements
@import 'global/containers';
@import 'global/flex';
@import 'global/typography';
@import 'global/colors';

// Overriding antd components
@import 'components/breadcrumb';
@import 'components/pagination';
@import 'components/table';
@import 'components/button';
@import 'components/modal';
@import 'components/notifications';
@import 'components/form/checkbox';
@import 'components/form/input';
@import 'components/form/radio';
@import 'components/tabs';
@import 'components/map';

// Utility classes
@import 'util/aligns';
@import 'util/spacing';
@import 'util/overflow';
@import 'util/helpers';
@import 'util/sizing';



// Temp

.standard-text {
	font-size: 1.25rem; //20px
	line-height: 1.6875rem; // 27px
}

.title {
	.standard-text();
	font-weight: 700;
}

