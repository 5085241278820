@import '../../variables';

.ant-input-clear-icon {
	color: @primary-color;
}

input {
	.standard-text();
	border: none ;
	border-bottom: 1px solid @gray-base;
	padding-bottom: 5px;
	width: 100%;
	background-color: transparent;
	outline: none !important;
	&::placeholder {
		opacity: 0.5;
	}

	&.standard {
		height: 45px;
		padding: 0 10px ;
		display: flex;
		align-items: center;
		border-color: @green-base;
		border: 1px solid @green-base;
		background-color: #FFF;
		&::placeholder {
			color: @green-base;
			opacity: 0.5;
		}
		&:disabled {
			background-color: rgba(221,221,221, 0.3);
			border: 1px solid rgba(112,112,112, 0.3);
			&::placeholder {
				color: @gray-base;
				opacity: 0.5;
			}
		}
	}
	&.transparent {
		border: none;
		background-color: transparent;
		padding: 0;
		.standard-text();
		&.editable {
			border-bottom: 2px solid @gray-base;
		}
		&__title {
			font-size: 32px;
			font-weight: bold;
			color: @green-base;
			&.editable {
				border-bottom: 2px solid @green-base;
			}
		}
	}
}
