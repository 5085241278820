.ant-checkbox {
	.ant-checkbox-inner {
		border-radius: 6px;
		border-color: @primary-color;
		box-shadow: inset 0 0 10px rgba(0, 0, 0, .25);

		&:after {
			border-width: 3px;
			margin-top: -1px;
			margin-left: -1px;
		}
	}

	&.ant-checkbox-checked {
		.ant-checkbox-inner {
			background: @component-background;
		}

		&:after {
			border-radius: 6px;
		}
	}
}

.ant-checkbox-wrapper {
	.ant-checkbox + span {
		display: inline-block;
		margin-left: @checkbox-size * -1;
		width: 100%;
		padding-left: @checkbox-size + @checkbox-group-item-margin-right;
		vertical-align: top;
	}
}
