.ant-table {
	border: 1px solid @primary-color;

	.ant-table-thead {
		> tr {
			> th {
				font-weight: bold;
				border-bottom: 1px solid @primary-color;

				.ant-table-column-sorter {
					margin: -0.5em 0.25em;
					position: relative;
					top: -1px;

					.anticon {
						font-size: @font-size-base;
					}
				}
			}
		}
	}

	tr {
		transition: all @animation-duration-base;

		&:hover {
			background: @table-row-hover-bg;
		}
	}
}
