each(range(27),{
	.m-@{value} { margin: @value * 4px; }
	.my-@{value} {
		margin-top: @value * 4px;
		margin-bottom: @value * 4px;
	}
	.mx-@{value} {
		margin-left: @value * 4px;
		margin-right: @value * 4px;
	}
	.mt-@{value} { margin-top: @value * 4px !important; }
	.mb-@{value} { margin-bottom: @value * 4px; }
	.ml-@{value} { margin-left: @value * 4px; }
	.mr-@{value} { margin-right: @value * 4px; }
	.p-@{value} { padding: @value * 4px; }
	.py-@{value} {
		padding-top: @value * 4px;
		padding-bottom: @value * 4px;
	}
	.px-@{value} {
		padding-left: @value * 4px;
		padding-right: @value * 4px;
	}
	.pt-@{value} { padding-top: @value * 4px; }
	.pb-@{value} { padding-bottom: @value * 4px; }
	.pl-@{value} { padding-left: @value * 4px; }
	.pr-@{value} { padding-right: @value * 4px !important; }
});
