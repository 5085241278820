.ant-tabs {
	&-tab {
		color: @gray-base !important;
		padding: 0 15px !important;
		margin: 0 !important;
		height: 75px;
		display: flex;
		align-items: center;
		background-color: #FFF !important;
		border-bottom: 1px solid @gray-base !important;
		border-left: 1px solid @gray-base !important;
		border-top: 1px solid @gray-base !important;
		font-size: 18px;
		font-weight: 700 !important;
		flex: 1;
		justify-content: center;
		white-space: normal;
		text-align: center;
	}

	&-tab-active {
		background-color: @gray-base !important;
		background: @gray-base !important;
		.ant-tabs-tab-btn {
			color: #FFF !important;
			font-weight: 700 !important;
		}
	}
}
.ant-tabs-nav {
	border: none !important;
}
.ant-tabs-nav-list {
	width: 100%;
}
.ant-tabs-content {
	padding: 0 30px 40px 30px;
}
.ant-tabs-nav-operations {
	display: none !important;
}
