/* You can add global styles to this file, and also import other style files */
@import '~@metro/ui/assets/styles/styles';

body {
	overflow-y: auto;
}
h3 {
	margin: 0;
}
h4 {
	font-size: 22px;
	font-weight: 700;
	line-height: 27px;
	margin: 0;
}
h5 {
	font-weight: 700;
	font-size: 18px;
}
h6 {
	font-size: 18px;
	font-weight: 400;
	margin: 0;
}

.cdk-global-scrollblock{
	position: static !important;
	width: initial !important;
	overflow-y: inherit !important;
	body {
		overflow-y: hidden;
	}
}
.ant-radio-wrapper {
	font-size: 18px;
	line-height: 27px;
}
.ant-radio {
	span  {
		font-size: 18px;
		line-height: 27px;
	}
}

.invalid-message {
	color: #F24949;
	position: absolute;
	bottom: 0px;
	transform: translateY(100%);
	font-size: 14px;
	white-space: pre;
}

.pointer {
	cursor: pointer;
}
.d-inline-block {
	display: inline-block;
}
.left {
	float: left;
}
.right {
	float: right;
}

.anticon-delete {
	cursor: pointer;
	&:hover {
		svg {
			fill: red;
		}
	}
}

.ant-table-cell {
	//min-height: 45px;
	border: none !important;
}

.ant-table-row {
	border-bottom: 1px solid #F1F1F1
}

table {
	border-collapse: collapse !important;
}

.text-underline {
	text-decoration: underline;
}
