.ant-btn {
	text-transform: uppercase;
}

button {
	.standard-text();
	display: flex;
	align-items: center;
	white-space: nowrap;
	border-radius: 0px;
	padding: 10px 30px;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0,0.2);
	-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0,0.2);
	cursor: pointer;
	outline: none ;
	&.primary {
		background-color: @green-base;
		border: 2px solid @green-base;
		color: @white !important;
		&:hover {
			background-color: @green-10;
			border-color: @green-10;
		}
		&:active, &:focus {
			background-color: @green-10;
			border-color: @green-10;
		}
	}
	&.disabled {
		cursor: initial;
		pointer-events: none;
		filter: grayscale(1);
	}
	&.secondary {
		background-color: @white;
		border: 2px solid #B8B8B8;
		color: @gray-base;
		&:hover {
			background-color: #DDDDDD;
		}
		&:active, &:focus {
			background-color: #DDDDDD;
		}
	}
	&.initial {
		border: 1px solid @green-1;
		font-size: 1.75rem ;
		font-weight: bold;
		line-height: 	2.6875rem;
		width: 100%;
		max-width: 480px;
		padding: 15px 40px;
		color: @green-base;
		background-color: @white;
		span {
			margin: 0 auto;
		}
		&:hover {
			span {
				text-decoration: underline;
			}
		}
	}
}
