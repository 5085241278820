@import '../variables';

.ant-pagination {
	justify-content: center;

	li {
		&.ant-pagination-prev, &.ant-pagination-next, &.ant-pagination-jump-prev, &.ant-pagination-jump-next, &.ant-pagination-item {
			margin: 0;
			border: 1px solid @primary-light-color;
			border-right: none;
		}

		&.ant-pagination-prev, &.ant-pagination-next {
			.ant-pagination-item-link {
				border: none;
			}
		}

		&:last-of-type {
			border-right: 1px solid @primary-light-color;
		}

		&.ant-pagination-jump-prev, &.ant-pagination-jump-next {
			.anticon {
				vertical-align: -0.05em;
			}
		}

		&.ant-pagination-item-active {
			a {
				color: @white;
			}
		}
	}

	&.ant-table-pagination {
		margin: 32px 0;
	}

	&.mini {
		li.ant-pagination-item:not(.ant-pagination-item-active) {
			border: 1px solid @primary-light-color;
			border-right: none;
		}
	}
}
